import React, { useState, useEffect, useRef } from 'react';
import MyFyeIcon from '../assets/myFyeLogo.png';
import FinFriendIcon from '../assets/FinFriendIcon.png';
import buci from '../assets/buci.jpg';
import whiteTriangle from '../assets/whiteTriangle.png';
import whiteX from '../assets/whiteX.png';
import grayX from '../assets/grayX.png';
import grayTriangle from '../assets/grayTriangle.png';

interface CompanySelectorProps {
  changingScrollPosition: boolean;
}

const CompanySelector: React.FC<CompanySelectorProps> = ({ changingScrollPosition }) => {

  const refOne = useRef<HTMLDivElement>(null);
  const refTwo = useRef<HTMLDivElement>(null);
  const refThree = useRef<HTMLDivElement>(null);
  const refFour = useRef<HTMLDivElement>(null);
  const isSmallScreen = window.innerWidth <= 768;
  const isReallyBigScreen = window.innerWidth >= 1100;

  type BlurbKey = 'one' | 'two' | 'three' | 'four';

  const [blurbHeight, setBlurbHeight] = useState({
    one: 0,
    two: 0,
    three: 0,
    four: 0,
  });


const toggleBlurb = (blurb: BlurbKey) => {
    // Toggle the clicked blurb and set all others to 0 (hidden)
    const updatedHeights = {
      one: 0,
      two: 0,
      three: 0,
      four: 0,
    };

    const refMap = {
      one: refOne,
      two: refTwo,
      three: refThree,
      four: refFour,
    };

    const refCurrent = refMap[blurb]?.current;
    const maxHeight = 240;
    if (refCurrent && blurbHeight[blurb] === 0) {
      if (isSmallScreen) {
      updatedHeights[blurb] = refCurrent.scrollHeight;
      } else {
      updatedHeights[blurb] = Math.min(refCurrent.scrollHeight, maxHeight);
      }
    }
    setBlurbHeight(updatedHeights);
  };
  

  useEffect(() => {

    const updatedHeights = {
      one: 0,
      two: 0,
      three: 0,
      four: 0,
    };

    if (changingScrollPosition) {
      setBlurbHeight(updatedHeights);
    }
}, [changingScrollPosition]);

  return (
    <div>
      <div style={{display: 'flex', flexDirection: 'column', 
      width: isSmallScreen ? '100vw': '90vw',
      justifyContent: 'center',
      gap: isSmallScreen ? '10px' : '20px', color:'#333333', 
      fontSize: isSmallScreen ? '18px' : '18px', 
      marginLeft: isSmallScreen ? '0px' : '0px', marginTop: '5px',
      zIndex: 10,
      position: 'relative'}}>
        
        <hr style={{ border: 'none', borderTop: '1.5px solid #666666', margin: '0px 0' }} />

        <div onClick={() => toggleBlurb('one')} style={{display: 'flex', 
          flexDirection: 'row', alignItems: 'center', gap: '30px', cursor: 'pointer'}}>
          <img src= {buci} alt="" style={{ 
              marginLeft: isSmallScreen ? '10px' : '0px',
              width: isSmallScreen ? '50px' : '40px',
              borderRadius: isSmallScreen ? '3px' : '4px',
            boxShadow: '0px 4px 6px rgba(0, 0.1, 0.1, 0.5)'}}></img>

          <div style={{fontSize: isSmallScreen ? '18px' : '24px'}}>Technical Lead At Blockchain UCI</div>
          <img src={ blurbHeight.one > 0 ? grayX : grayTriangle} 
            alt="open/close icon"
            style={{
              
            width: isSmallScreen ? '25px' : '20px', 
            height: 'auto', 
            marginLeft: 'auto', 
            marginRight: isSmallScreen ? '40px' : '15vw'}}/>
        </div>

        <div ref={refOne} style={{overflow: 'hidden', transition: 'height 800ms ease', height: `${blurbHeight.one}px`}}>
          <div style={{fontSize: isSmallScreen ? '18px' : '18px', lineHeight: '1.5', paddingLeft: isSmallScreen ? '10px' : '0px',}}>
            <a href="https://www.blockchainuci.org/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ 
                  color: '#333333', 
                  cursor: 'pointer', 
                  fontSize: isSmallScreen ? '18px' : '18px',
                  textDecoration: 'underline', // Add underline explicitly
                  textDecorationColor: '#333333' // Set underline color to match text color
                }}>
              BlockchainUCI.org
              </div>
            </a>
            <br/>
            <div style={{marginTop: '-20px'}}>
            ● Engineered the Web3 architecture for a decentralized rental marketplace, integrating Base and  
           Coinbase Smart Wallet via the Coinbase Developer Platform, securing a victory at the Eigen Games
           EthDenver 2025 hackathon<br/>
                    ●  Managed open source GitHub repositories by assigning tasks, reviewing code, approving pull 
                        requests, and overseeing branch management of over <span style={{fontWeight: 'bold'}}>10</span> pull requests per week<br/>
                    ●  Coordinated over <span style={{fontWeight: 'bold'}}>30</span> technical interviews and made strategic hiring decisions

                <br/>
            </div>
          </div>
        </div>

        <hr style={{ border: 'none', borderTop: '1.5px solid #666666', margin: '0px 0' }} />

        <div onClick={() => toggleBlurb('two')} style={{display: 'flex', 
          flexDirection: 'row', alignItems: 'center', gap: '30px', cursor: 'pointer'}}>
            <img
            src= {MyFyeIcon}
            alt=""
            style={{
              marginLeft: isSmallScreen ? '10px' : '0px',
              width: isSmallScreen ? '50px' : '40px',
              borderRadius: isSmallScreen ? '3px' : '4px',
              height: 'auto',
              boxShadow: '0px 4px 6px rgba(0, 0.1, 0.1, 0.5)'
            }}></img>
          <div style={{fontSize: isSmallScreen ? '18px' : '24px'}}>Co-Founder / Software Engineer At Myfye</div>
          <img src={ blurbHeight.two > 0 ? grayX : grayTriangle}
          alt="open/close icon" 
          style={{width: isSmallScreen ? '25px' : '20px', height: 'auto', marginLeft: 'auto', marginRight: isSmallScreen ? '40px' : '15vw'}}/>
        </div>

        <div ref={refTwo} style={{overflow: 'hidden', transition: 'height 800ms ease', height: `${blurbHeight.two}px`}}>


          <div style={{fontSize: isSmallScreen ? '18px' : '18px', lineHeight: '1.5', paddingLeft: isSmallScreen ? '10px' : '0px',}}>

            <div style={{display: 'flex', gap: '30px'}}>

            <a 
              href="https://myfye.com/" 
              target="_blank" 
              rel="noopener noreferrer" 
              style={{ color: 'inherit', textDecoration: 'none' }} // Remove default underline
            >
              <div 
                style={{ 
                  color: '#333333', 
                  cursor: 'pointer', 
                  fontSize: isSmallScreen ? '18px' : '18px',
                  textDecoration: 'underline', // Add underline explicitly
                  textDecorationColor: '#333333' // Set underline color to match text color
                }}
              >
                Myfye.com
              </div>
            </a>
            </div>

            <br/>
            <div style={{marginTop: isSmallScreen? '-35px' : '-25px'}}>
            ●  Created a banking dApp focused on tokenizing real world assets, such as U.S. Government 
                Treasury bills managing over <span style={{fontWeight: 'bold'}}>1,500</span> transactions seamlessly, built with Solana<br/>
            ●  Engineered on-chain swap solutions with Jupiter to deliver a highly optimized user experience, 
                achieving <span style={{fontWeight: 'bold'}}>99.7%</span> swap reliability with a seamless one-click experience<br/>
            ●  Managed and integrated complex NPM dependency trees, ensuring stability of web3 libraries<br/>
            ●  Developed sponsored transactions and swaps, eliminating fees for users 
            </div>
            </div>
        </div>
                

        <hr style={{ border: 'none', borderTop: '1.5px solid #666666', margin: '0px 0' }} />
        <div 
          onClick={() => toggleBlurb('three')}
          style={{display: 'flex', 
          flexDirection: 'row', alignItems: 'center', gap: '30px', cursor: 'pointer'
          }}>

            <img
            src= {FinFriendIcon}
            alt=""
            style={{
              marginLeft: isSmallScreen ? '10px' : '0px',
              width: isSmallScreen ? '50px' : '40px',
              borderRadius: isSmallScreen ? '18px' : '4px',
              height: 'auto',
              boxShadow: '0px 4px 6px rgba(0, 0.1, 0.1, 0.5)'
            }}></img>

            <div style={{fontSize: isSmallScreen ? '18px' : '24px'  }}>
              Founding Engineer At FinFriend
            </div>
            <img src={ blurbHeight.three > 0 ? grayX : grayTriangle} 
            alt="open/close icon"
            style={{width: isSmallScreen ? '25px' : '20px', height: 'auto', marginLeft: 'auto', marginRight: isSmallScreen ? '40px' : '15vw'}}/>
        </div>

        <div
          ref={refThree}
          style={{
          overflow: 'hidden',
          transition: 'height 800ms ease',
          height: `${blurbHeight.three}px`,
          }}>
          <div style={{fontSize: isSmallScreen ? '18px' : '18px', paddingLeft: isSmallScreen ? '10px' : '0px', lineHeight: '1.5'}}> 
            <div style={{display: 'flex', gap: '30px'}}>
              <a href="https://finfriend.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ 
                  color: '#333333', 
                  cursor: 'pointer', 
                  fontSize: isSmallScreen ? '18px' : '18px',
                  textDecoration: 'underline', // Add underline explicitly
                  textDecorationColor: '#333333' // Set underline color to match text color
                }}>
                FinFriend.com
              </div>
              </a>
            </div>
              <br/>
              <div style={{marginTop: isSmallScreen ? '-20px' : '-20px'}}>
              ●  Built a create-your-own-currency platform for iOS, Android, and the web that has made over <span style={{fontWeight: 'bold'}}>$1,000 </span>
           in revenue and has seen over <span style={{fontWeight: 'bold'}}>100</span> free trial currencies launched<br/>
      ●  Engineered a digital asset exchange with advanced computer science techniques that has traded  
          Over <span style={{fontWeight: 'bold'}}>$5,000</span> in volume<br/>
      ●  Designed the frontend using Adobe and built it natively in Swift, Kotlin and React

                <br/>
              </div>
            </div>
        </div>

                {isSmallScreen && (
              <hr style={{ border: 'none', borderTop: '1.5px solid #666666', marginTop: '10px', width: '100vw' }} />
              )}
              { !isSmallScreen && (
            <hr style={{ border: 'none', borderTop: '1.5px solid #666666', margin: '0px 0' }} />
              )}
            </div>

    </div>



  );
}

export default CompanySelector;