import React from 'react';
import logo from './logo.svg';
import './App.css';
import { HashRouter, Routes, Route as Router, Route } from 'react-router-dom';
import Home from './pages/Home';
/*
function App() {
  return (
    <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}
*/

function App() {
  return (
    <div>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </HashRouter>
    </div>
  );
}


export default App;
