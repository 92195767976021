import React, { useState, useEffect } from 'react';

export default function Contact() {
  const [emailText, setEmailText] = useState('gavindmilligan@gmail.com');
  const [opacity, setOpacity] = useState(1);
  const [isCopied, setIsCopied] = useState(false);
  const isSmallScreen = window.innerWidth <= 768;

  const copyToClipboard = (text: string) => {
    /* Copy email animation */
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true);
      setOpacity(0); // Start fading out
      setTimeout(() => {
        setEmailText('Copied!');
        setOpacity(1); // Start fading in
        setTimeout(() => {
          setOpacity(0); // Start fading out again after 2 seconds
          setTimeout(() => {
            setEmailText('gavindmilligan@gmail.com');
            setOpacity(1); // Fade back in to show the email
            setIsCopied(false);
          }, 250); // Match this duration with the CSS transition duration
        }, 1000);
      }, 250); // Match this duration with the CSS transition duration
    });
  };

  return (
<div style={{ zIndex: 10, position: 'relative' }}>
  <div style={{ textAlign: 'center', fontSize: isSmallScreen ? '35px' : '55px', color: '#333333' }}>
    Contact
  </div>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
    <div
      style={{
        border: '2px solid #333333',
        borderRadius: '10px',
        color: '#333333',
        display: 'inline-block',        // Makes border width fit text
        padding: '5px 20px',            // Adds padding for spacing
        textAlign: 'center'
      }}
    >
      <div
        style={{
          fontSize: isSmallScreen ? '18px' : '25px',
          paddingBottom: '5px',
          paddingTop: '5px',
          cursor: 'pointer',
          textAlign: 'center',
          opacity: opacity,
          transition: 'opacity 0.5s ease'
        }}
        onClick={() => copyToClipboard('gavindmilligan@gmail.com')}
      >
        {emailText}
        <div style={{ visibility: 'hidden', height: 0 }}>{'gavindmilligan@gmail.com'}</div>
      </div>
    </div>
  </div>
</div>

  );
}
